import Vue from 'vue'
import {baseConsoleRoutes} from '~bac/router'

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',                                              //首页
      name: 'index',
      component: resolve => require(['~dlc/views/index'], resolve),

    },
    {
      path: '/downloadSection',                                              //首页
      name: 'downloadSection',
      component: resolve => require(['~dlc/views/index/downloadSection/index'], resolve),
    },
    {
      path: '/activitys',                                              //首页--活动
      name: 'activitys',
      component: resolve => require(['~dlc/views/index/activitys/index'], resolve),
      children: []
    },
    {
      path: '/dynamic',//协会动态
      name: 'dynamic',
      component: resolve => require(['~dlc/views/info/dynamic'], resolve),
      children: []
    },
    {
      path: '/notice',//公示公告
      name: 'notice',
      component: resolve => require(['~dlc/views/info/notice'], resolve),
      children: []
    },
    {
      path: '/activity',                                  //培训活动
      name: 'activity',
      component: resolve => require(['~dlc/views/activity/index'], resolve)
    },
    {
      path: '/memberShip',                                              //申请入会
      name: 'memberShip',
      component: resolve => require(['~dlc/views/index/memberShip/index'], resolve),
      children: [
        {
          path: '/',
          name: 'applyFor',
          component: resolve => require(['~dlc/views/index/memberShip/flow'], resolve)
        }, {
          path: '/memberShip/applyFor',
          name: 'applyFor',
          component: resolve => require(['~dlc/views/index/memberShip/applyFor'], resolve)
        }]
    },
    {
      path: '/college',                                  //物贸学院
      name: 'college',
      component: resolve => require(['~dlc/views/college/index'], resolve)
    },
    {
      path: '/college_detail',                                  //物贸学院 详情
      name: 'college_detail',
      component: resolve => require(['~dlc/views/college/detail'], resolve)
    },
    {
      path: '/positionList',                                  //人才中心
      name: 'positionList',
      component: resolve => require(['~dlc/views/position/list'], resolve)
    },
    {
      path: '/positionDetail',                                //职位详情
      name: 'positionDetail',
      component: resolve => require(['~dlc/views/position/detail'], resolve)
    },
    {
      path: '/talents/library',                                //人才库
      name: 'library',
      component: resolve => require(['~dlc/views/talents/library'], resolve)
    },
    {
      path: '/survey',                                              //协会概况
      name: 'survey',
      component: resolve => require(['~dlc/views/survey'], resolve),
      children: [{
        path: '/',
        name: 'brief',
        component: resolve => require(['~dlc/views/survey/brief'], resolve)
      }, {
        path: '/survey/director',
        name: 'director',
        component: resolve => require(['~dlc/views/survey/director'], resolve)
      }, {
        path: '/survey/framework',
        name: 'framework',
        component: resolve => require(['~dlc/views/survey/framework'], resolve)
      }, {
        path: '/survey/leader',
        name: 'leader',
        component: resolve => require(['~dlc/views/survey/leader'], resolve)
      }, {
        path: '/survey/supervisor',
        name: 'supervisor',
        component: resolve => require(['~dlc/views/survey/supervisor'], resolve)
      }, {
        path: '/survey/member',
        name: 'member',
        component: resolve => require(['~dlc/views/survey/member'], resolve)
      }, {
        path: '/survey/constitution',
        name: 'constitution',
        component: resolve => require(['~dlc/views/survey/constitution'], resolve)
      }, {
        path: "/survey/memorabilia",
        name: 'memorabilia',
        component: resolve => require(['~dlc/views/survey/memorabilia'], resolve)
      }]
    }, {
      path: '/companyCatalog',//会员名录
      name: 'companyCatalog',
      component: resolve => require(['~dlc/views/company/companyCatalog'], resolve)
    }, {
      path: '/companyContactList',
      name: 'companyContactList',
      component: resolve => require(['~dlc/views/company/companyContactList'], resolve)
    }, {
      path: '/companyDetail',
      name: 'companyDetail',
      component: resolve => require(['~dlc/views/company/companyDetail'], resolve)
    }, {
      path: '/sign',
      name: 'sign',
      component: resolve => require(['~dlc/views/users/sign'], resolve),
      meta: {
        layout: 'sign'
      }
    },
    {
      path: '/forgetPassword',
      name: 'forgetPassword',
      component: resolve => require(['~dlc/views/users/forgetPassword'], resolve),
    },
    {
      path: '/resetPassword',
      name: 'resetPassword',
      component: resolve => require(['~dlc/views/users/resetPassword'], resolve),
    },
    {
      path: '/signUp',
      name: 'signUp',
      component: resolve => require(['~dlc/views/users/signUp'], resolve),
      meta: {
        layout: 'sign'
      }
    }, {
      path: '/activeAccount',
      name: 'activeAccount',
      component: resolve => require(['~dlc/views/users/activeAccount'], resolve),
    }, {
      path: '/memberCenter',
      name: 'memberCenter',
      component: resolve => require(['~dlc/views/memberCenter'], resolve)
    },
    {
      path: "/creditAssessment",//信用评估页面
      name: "creditAssessment",
      component: resolve => require(['~dlc/views/creditAssessment'], resolve)
    },
    {
      path: "/creditDetail",//信用评估详情页面
      name: "creditDetail",
      component: resolve => require(['~dlc/views/creditAssessment/detail'], resolve)
    },
    {
      path: "/basicInfo",//完善信息
      name: "basicInfo",
      component: resolve => require(['~dlc/views/users/basicInfo'], resolve)
    },
    {
      path: "/contactUs",//完善信息
      name: "contactUs",
      component: resolve => require(['~dlc/views/contactUs/index'], resolve)
    },
    {
      path: "/detail",//完善信息
      name: "detail",
      component: resolve => require(['~dlc/views/index/detail'], resolve)
    },
    {
      path: "/activityDetail",//首页活动详情
      name: "activityDetail",
      component: resolve => require(['~dlc/views/index/activityDetail'], resolve)
    },
    {
      path: "/memberInfo",
      name: "memberInfo",
      component: resolve => require(['~dlc/views/index/detail/companyInfo'], resolve)
    },
    {    // 风险
      path: "/warningList",
      name: "warningList",
      component: resolve => require(['~dlc/views/warningList/index'], resolve)
    },
    {
      path: '/handleInitialParams',
      name: 'handleInitialParams',
      meta: {
        layout: 'empty'
      },
      component: resolve => require(['~dlc/views/users/initPage'], resolve)
    },
    {
      path: '/baseShippingDate',                                              //查船期
      name: 'indeShip',
      component: resolve => require(['@/baseComponents/baseShippingDate/index'], resolve),
    },
    {
      path: "/overseas",//直通海外
      name: "overseas",
      component: resolve => require(['@/baseComponents/baseInquiry/overseas/index'], resolve)
    },
    {
      path: "/publishInquiry",//一键发盘
      name: "publishInquiry",
      component: resolve => require(['@/baseComponents/baseInquiry/publishInquiry/index'], resolve)
    },
    {
      path: '/talents/recruitment',//招聘信息
      name: 'recruitment',
      component: resolve => require(['~dlc/views/talents/recruitment'], resolve)
    },
    {
      path: '/talents/library',//人才库
      name: 'library',
      component: resolve => require(['~dlc/views/talents/library'], resolve)
    },
  ].concat(baseConsoleRoutes).concat([{
    path: "**",
    redirect: "/"
  }]),
  scrollBehavior(to, from, savedPosition) {
    return {x: 0, y: 0}
  }
})
