<template>
  <div>
    <el-menu
      class="el-menu-dlc"
      mode="horizontal"
      :default-active="activeIndex"
      menu-trigger="click"
      @select="selectMenu"
      :popper-append-to-body="false"
    >
      <el-menu-item index="/">网站首页</el-menu-item>
      <el-menu-item index="/survey">协会介绍</el-menu-item>
      <el-submenu index="/2" :popper-append-to-body="false">
        <template slot="title">会员服务</template>
        <el-menu-item index="/companyCatalog">企业名录</el-menu-item>
        <el-menu-item index="/companyContactList">联系人列表</el-menu-item>
      </el-submenu>
      <el-menu-item index="/college">教育培训</el-menu-item>
      <el-menu-item index="/dynamic">协会动态</el-menu-item>
      <el-menu-item index="/notice">公示公告</el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "navMenuItem",
  data() {
    return {
      activeIndex: "/",
    }
  },
  methods: {
    selectMenu(index, path) {
      console.log(index,path)
      if (index === "/talents/library") {
        if (!this.USER_INFO || !this.USER_INFO.aila_no) {
          this.isMumber = true
          return
        }
      }
      if (index == "") {
        this.dialogVisible = true
        return
      }
      this.$router.push(index)
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-menu.el-menu--horizontal{
  border-bottom: none;
}
/deep/ .el-menu-dlc {
  background-color: #2d8bce !important;
  display: flex;
  justify-content: space-between;
  .el-menu-item {
    color: #fff !important;
  }
  .el-submenu__title {
    color: #fff !important;
  }
  .el-menu-item:not(.is-disabled):hover {
    background-color: #0665a9;
  }
  .el-menu-item:not(.is-disabled):focus {
    background-color: #0665a9;
  }
  .el-submenu .el-submenu__title:hover {
    background-color: #0665a9;
  }
  .el-submenu .el-submenu__title:focus {
    background-color: #0665a9;
  }
  .el-menu--popup {
    background-color: #2d8bce !important;
    .el-menu-item {
      background-color: #2d8bce !important;
    }
    .el-menu-item:not(.is-disabled):focus {
      background-color: #0665a9;
    }
    .el-menu-item:not(.is-disabled):hover {
      background-color: #0665a9;
    }
  }
}
</style>
