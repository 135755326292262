<template>
  <div class="navMenu">
    <div class="topHeader">
      <div class="content_area top-wrap">
        <div class="top-left">
          你好，欢迎登录大连市仓储协会
        </div>
        <div class="routerLink">
          <span v-if="!IS_LOGIN">
            <router-link to="/sign" class="goLogin">
              <img
                  class="user-img"
                  src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/dlc/home/icon12.png"
                  alt=""
              />
            </router-link>
          </span>
          <span class="loginhas" v-else>
            <el-popover
                placement="bottom-start"
                trigger="click"
                v-model="visible"
            >
              <div class="navMenuselfAccountInfo">
                <p class="textOverflow">
                  姓名：{{
                    USER_INFO.name_en | priorFormat(USER_INFO.name_zh, LOCALE)
                  }}
                </p>
                <p class="textOverflow">
                  职位：{{
                    USER_INFO.job_title_en
                        | priorFormat(USER_INFO.job_title_zh, LOCALE)
                  }}
                </p>
                <p class="textOverflow">
                  邮箱：{{ USER_INFO.email | textFormat }}
                </p>
                <p class="textOverflow">
                  手机号：{{ USER_INFO.mobile | textFormat }}
                </p>
              </div>
              <div class="navMenuselfAccountInfoBtn">
                <el-button
                    style="background:#2d8bce;border:1px solid #2d8bce;"
                    class="logout"
                    type="danger"
                    size="small"
                    @click="
                    visible = false
                    logOut()
                  "
                >退出登录</el-button
                >
              </div>
              <img
                  :src="USER_INFO.avatar || defaultAvatar"
                  alt
                  class="userAvatar cursor"
                  slot="reference"
              />
            </el-popover>
            <router-link to="/console/account/userInfo"
            >控制台</router-link
            >
          </span>
          <span class="apply cursor">
            <span @click="membership">申请入会</span>
            <el-divider class="divider" direction="vertical"></el-divider>
            <span @click="AddFavorite">收藏</span>
          </span>
        </div>
      </div>
    </div>
    <div class="login-wrap">
      <div class="content_area logo-wrap-content">
        <div class="logo-content">
          <img
              class="logo"
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/dlc/home/logo.png"
              alt=""
          />
          <div class="logo-name">
            <div class="cn-name">
              大连市仓储与配送协会
            </div>
            <div class="en-name">
              Dalian Association of Warehousing and Distribution
            </div>
          </div>
        </div>
        <div class="search">
          <el-autocomplete
              size="mini"
              v-model="searchVal"
              :fetch-suggestions="querySearchCompanyName"
              placeholder="请输入内容"
              :trigger-on-focus="false"
          >
          </el-autocomplete>
          <div class="button" @click="searchContent">
            <img
                src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/dlc/home/icon13.png"
                alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="navMenuLink ">
      <div class="content_area">
        <navMenuItem/>
      </div>
    </div>
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="300px"
        :close-on-click-modal="true"
    >
      <div class="tc openDialog">暂未开放</div>
    </el-dialog>
    <el-dialog
        title="提示"
        :visible.sync="isMumber"
        width="300px"
        :close-on-click-modal="true"
    >
      <div class="tc openDialogs">
        <span class="blue" @click="goToMember">成为会员</span>,解锁专属权益
      </div>
    </el-dialog>
  </div>
</template>

<script>
import searchCompany from "~dlc/components/company/unit/searchCompany"
import navMenuItem from "~dlc/components/layouts/navMenuItem"

export default {
  components: {
    searchCompany,
    navMenuItem,
  },
  data() {
    return {
      isMumber: false,
      visible: false,
      defaultAvatar:
          "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/headportrait.png",

      menuLogo:
          "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/mpa/logo.png",
      searchVal: "",
      zhReg: /^[\u4e00-\u9fa5]+$/i,
      dialogVisible: false,
    }
  },
  methods: {
    logOut() {
      this.$store.commit("baseStore/CLEAR_USER_INFO")
      this.$router.push("/sign")
    },
    // 搜索框
    searchContent() {
      if (this.searchVal) {
        this.$router.push({
          path: "/companyCatalog",
          query: {
            parameter: this._encode({
              searchVal: this.searchVal,
            }),
          },
        })
      } else {
        this.$router.push("/companyCatalog")
      }
    },
    async querySearchCompanyName(queryString, cb) {
      let params = {}
      params.company_name = queryString
      // params.certificate = 20;
      params.source = this.PJSource
      params.usage = 2
      params.is_vip = 1
      // params.genre_ids = this.genreType;
      let res = await this.$store.dispatch(
          "baseStore/company_associateSearch",
          params
      )
      let values
      if (this.zhReg.test(queryString)) {
        values = res.data.map((item) => {
          item.value = item.name_zh
          return item
        })
      } else {
        // 输入是英文或者是数字
        values = res.data.map((item) => {
          if (item.name_en) {
            // 返回数据中，英文有值
            item.value = item.name_en
          } else {
            item.value = item.name_zh
          }
          return item
        })
      }
      cb(values)
    },
    handleActive(path) {
      if (path.indexOf("survey") != -1) {
        this.activeIndex = path.substring(0, 7)
      } else {
        this.activeIndex = path
      }
    },

    goToMember() {
      this.isMumber = false
      this.$router.push("/memberShip")
    },
    membership() {
      this.$router.push('/memberShip')
    },
    AddFavorite() {
      let title="大连仓储与配送协会"
      let url=window.location.href
      console.log(url)
      try {
        window.external.addFavorite(url, title);
      } catch (e) {
        try {
          window.sidebar.addPanel(title, url, "");
        } catch (e) {
          alert("抱歉，您所使用的浏览器无法完成此操作。\n\n加入收藏失败，请使用Ctrl+D进行添加");
        }
      }
    }
  },
  watch: {
    $route(nv) {
      this.handleActive(nv.path)
    },
  },
  mounted() {
  },
}
</script>

<style scoped lang="less">
.loginhas{
  display: flex;
  align-items: center;
  .userAvatar{
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}

.navMenu {
  .topHeader {
    background-color: #efefef;
    padding: 30px 0 10px;

    .top-wrap {
      display: flex;
      justify-content: space-between;

      .top-left {
        font-size: 14px;
        font-weight: 400;
        color: #676768;
      }

      .routerLink {
        display: flex;
        align-items: center;

        .goLogin {
          display: flex;
          align-items: center;

          .user-img {
            width: 14px;
            height: 14px;
          }
        }

        .apply {
          color: #676768;
          margin-left: 30px;

          .divider {
            position: relative;
            top: -2px;
          }
        }
      }
    }
  }

  .login-wrap {
    background-color: #fff;
    padding: 10px 0;

    .logo-wrap-content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logo-content {
        display: flex;

        .logo {
          width: 80px;
          height: 80px;
        }

        .logo-name {
          padding-left: 10px;
          padding-top: 10px;

          .cn-name {
            font-size: 26px;
            font-weight: 700;
            margin-bottom: 5px;
          }

          .en-name {
            font-size: 12px;
            color: #b4b4b4;
          }
        }
      }

      .search {
        display: flex;
        align-items: center;

        .button {
          width: 28px;
          height: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #2d8bce;
          margin-left: 4px;

          & > img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  .navMenuLink {
    width: 100%;
    background-color: #2d8bce;
  }
}
</style>
<style lang="less">
.el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
  min-width: 110px !important;
  text-align: center;
}
.navMenuselfAccountInfo {
  p {
    line-height: 28px;
  }
  .navMenuselfAccountInfoBtn {
    margin-top: 8px;
    .logout{
      background-color: #2d8bce!important;
      border: 1px solid #2d8bce!important;
    }
  }
}
.blue{
  color: #409eff;
  cursor: pointer;
}
</style>
