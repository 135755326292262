<template>
  <div class="siffaDefaultLayout">
    <navMenu></navMenu>
    <backPageTop></backPageTop>
    <router-view />
    <pageBottom></pageBottom>
  </div>
</template>

<script>
import navMenu from "~dlc/components/layouts/navMenu";
import pageBottom from "~dlc/components/layouts/pageBottom";
import backPageTop from "~/baseComponents/backPageTop";
export default {
  components: { navMenu, pageBottom, backPageTop },
  mounted() {},
};
</script>

<style scoped lang="less">
.siffaDefaultLayout {
  background: #fafafa;
}
</style>