import { render, staticRenderFns } from "./sign.vue?vue&type=template&id=eb11ffee&scoped=true"
import script from "./sign.vue?vue&type=script&lang=js"
export * from "./sign.vue?vue&type=script&lang=js"
import style0 from "./sign.vue?vue&type=style&index=0&id=eb11ffee&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb11ffee",
  null
  
)

export default component.exports