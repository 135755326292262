<template>
  <div class="footer">
    <div class="footer-top">
      <div class="wrap1 content_area">
        <div class="left">
          友情链接
        </div>
        <div class="right">
          <div class="right-item" v-for="(item,index) in list" :key="index">
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="wrap2 content_area">
        <div class="text1">
          <a href="">隐私政策</a>
          <el-divider class="divider" direction="vertical"></el-divider>
          <a href="">联系我们</a>
        </div>
        <div class="text2">
          版权所有：大连市仓储配送协会
        </div>
        <div class="text3">
          <img style="width: 15px;height: 15px;" src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/dlc/home/ba.png" alt="" srcset="">  <a href="https://beian.miit.gov.cn/" style="color: #99a7a8;" rel="noreferrer" target="_blank">辽公网安备21020202000701</a >
            辽ICP备2020014639号-1
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          title: '中国仓储于配送协会'
        },
        {
          title: '中国仓储于配送协会'
        },
        {
          title: '中国仓储于配送协会'
        },
        {
          title: '中国仓储于配送协会'
        },
        {
          title: '中国仓储于配送协会'
        },
        {
          title: '中国仓储于配送协会'
        },
        {
          title: '中国仓储于配送协会'
        },
        {
          title: '中国仓储于配送协会'
        },
        {
          title: '中国仓储于配送协会'
        },
      ]
    };
  },
  methods: {
    link_click(row) {
    },
    toHref(item) {
      window.open(item.href);
    },
  },
};
</script>

<style scoped lang="less">
.footer {
  .footer-top {
    background-color: #3d4042;
    padding: 20px;
    display: flex;
    align-items: center;

    .wrap1 {
      display: flex;
      .left {
        font-size: 20px;
        color: #fff;
        font-weight: 700;
        flex-shrink: 0;
        padding-right: 10px;
        position: relative;
        line-height: 80px;
        &:after{
          content:' ';
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 20px;
          background-color: #8f928c;

        }
      }

      .right {
        padding-left: 30px;
        height: 80px;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;

        .right-item {
          padding-left: 10px;
          position: relative;
          margin-right: 80px;
          color: #fff;
          &:after {
            content: ' ';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: #fff;
          }
        }
      }
    }


  }

  .footer-bottom{
    background-color: #575b5e;
    padding: 30px 20px 20px;
    text-align: center;
    .text1,.text2,.text3{
      color: #99a7a8;
      margin-bottom: 10px;
    }
    .text1{
      a{
        color: #99a7a8;
      }
      /deep/ .divider{
        position: relative;
        top: -2px;
      }
    }
  }
}
</style>
